import PaginationArrows from "@/components/PaginationArrows";
import ProfileCard from "@/components/ProfileCard";
import { ConfirmDeleteModal } from "@/features/Modal";
import useModalConfirm from "@/shared/lib/hooks/useModalComfirm";
import useModalOpen from "@/shared/lib/hooks/useModalOpen";
import { notify } from "@/shared/lib/utils/notify";
import { pagination } from "@/shared/lib/utils/pagination";
import Input from "@/widgets/Form/ui/Input";
import { IFaqTag, deleteFaqTag, getFaqTagList } from "@op/entities";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import userStore from "../../../app/providers/MobxStore/model/UserStore";
import FaqTagsModal from "../../../widgets/FaqTagsModal/ui/FaqTagsModal";

const PAGINATION_SIZE = 18;

function FaqTags() {
  const { t } = useTranslation();
  const [modalEditorOpened, setModalEditorOpened] = useModalOpen();
  const [modalEditorData, setModalEditorData] = useState<IFaqTag>();
  const [modalConfirm, setModalConfirm] = useModalConfirm();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<IFaqTag[]>();

  const [searchString, setSearchString] = useState("");
  const [currentPaginationPosition, setCurrentPaginationPosition] = useState(0);

  const getItems = async () => {
    try {
      setLoading(true);
      const tagListResponse = await getFaqTagList({
        token: userStore.token!,
      });
      setItems(tagListResponse.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value.toLowerCase());
    setCurrentPaginationPosition(0);
  };

  const handlePagination = (next?: boolean) => {
    setCurrentPaginationPosition(
      pagination(
        currentPaginationPosition,
        PAGINATION_SIZE,
        items?.length ?? 0,
        !!next
      )
    );
  };

  const handleConfirm = (id?: number) => {
    id
      ? setModalConfirm({ isOpen: true, id: id })
      : setModalConfirm({
          isOpen: false,
          id: null,
        });
  };

  const handleDelete = async () => {
    if (modalConfirm.id) {
      try {
        const response = await deleteFaqTag({
          id: modalConfirm.id,
          token: userStore.token!,
        });
        if (response) {
          setModalConfirm({ isOpen: false, id: null });
          await getItems();
        } else {
          throw new Error();
        }
      } catch (error) {
        notify(t("AnErrorOcurred"));
      }
    }
  };

  const handleEdit = (data: IFaqTag) => {
    if (data) {
      setModalEditorOpened(true);
      setModalEditorData(data);
    }
  };

  const handleAdd = () => {
    setModalEditorOpened(true);
    setModalEditorData(undefined);
  };

  const handleModalEditorOnSave = async () => {
    const { pageYOffset } = window;

    window.scrollTo(0, 0);
    await getItems();
    setTimeout(() => {
      window.scrollTo(0, pageYOffset);
    }, 1);
  };

  useEffect(() => {
    getItems();
  }, []);

  const filteredItems = items?.filter((item) =>
    item.name.toLowerCase().includes(searchString)
  );

  if (loading) {
    return (
      <>
        <div className="profile__title-block">
          <strong className="profile__title">{t("TagsList")}</strong>
        </div>
        <div className="profile__body profile-rubrics">
          <div>{t("Loading")}...</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="profile__title-block">
        <strong className="profile__title">{t("TagsList")}</strong>
        <button
          className="btn btn-fond blue scale-up lighten-up size-s profile__title-btn"
          onClick={handleAdd}
        >
          <span className="icon icon-plus btn__icon" />
          <i className="btn__text">{t("TagAdd")}</i>
        </button>
      </div>
      <div className="profile__control">
        <div className="profile__control-item">
          <div className="search-input profile__search">
            <Input
              placeholder={t("SearchByTags")}
              className="input_stroke profile__search-input"
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="profile__control-item">
          <div className="profile__control-block">
            <PaginationArrows
              onClick={handlePagination}
              currentPosition={currentPaginationPosition}
              paginationSize={PAGINATION_SIZE}
              catalogLength={items?.length ?? 0}
            />
          </div>
        </div>
      </div>
      <div className="profile__body profile-rubrics">
        {filteredItems
          ?.slice(
            currentPaginationPosition,
            currentPaginationPosition + PAGINATION_SIZE
          )
          .map((item) => (
            <ProfileCard
              key={item.id}
              className="card-rubric"
              cardId={item.id}
              cardLink={item.index}
              onDelete={handleConfirm}
              onEdit={() => handleEdit(item)}
              cells={[
                <React.Fragment key={1}>{item.name}</React.Fragment>,
                <React.Fragment key={1}>
                  {item.is_enabled ? t("TagShow") : t("TagHidden")}
                </React.Fragment>,
              ]}
            />
          ))}

        <div className="profile__pagination-bottom">
          <PaginationArrows
            onClick={handlePagination}
            currentPosition={currentPaginationPosition}
            paginationSize={PAGINATION_SIZE}
            catalogLength={items?.length ?? 0}
          />
        </div>
      </div>

      <FaqTagsModal
        open={modalEditorOpened}
        onClose={() => setModalEditorOpened(false)}
        data={modalEditorData}
        onSave={handleModalEditorOnSave}
      />
      <ConfirmDeleteModal
        text={t("TagDeleteConfirm")}
        modalOpen={modalConfirm.isOpen}
        setModalOpen={handleConfirm}
        onDelete={handleDelete}
      />
    </>
  );
}

export default observer(FaqTags);
