import { body } from './body';
import { colors } from './colors';
import { fontWeightBase } from './fonts';

export const scopedCssBaselineRoot = {
  '*': {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    outlineColor: colors.toxic.c_0,
    outlineWidth: 4,
    WebkitTapHighlightColor: 'rgba(255, 255, 255, 0)',
    WebkitTextSizeAdjust: 'none',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
  },
  body: {
    position: 'relative',
    height: '100%',
    fontSize: body.fontSize,
    lineHeight: body.lineHeight,
  },
  section: {
    position: 'relative',
    width: '100%',
  },
  a: {
    cursor: 'pointer',
    textDecoration: 'none',
    textTransform: 'none',
  },
  img: {
    display: 'block',
    border: 'none',
    textDecoration: 'none',
  },
  button: {
    cursor: 'pointer',
    textTransform: 'none',
  },
  em: {
    fontStyle: 'normal',
  },
  strong: {
    fontWeight: fontWeightBase,
  },
  address: {
    fontStyle: 'normal',
  },
  'a[href^="tel"]': {
    color: 'inherit',
    textDecoration: 'none',
  },
};
