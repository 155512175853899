export const OP_NAME = 'Online Patent';
export const OP_SUPPORT_EMAIL = 'support@onlinepatent.ru';
export const OP_PARTNERS_EMAIL = 'partners@onlinepatent.ru';
export const OP_PR_EMAIL = 'pr@onlinepatent.ru';
export const OP_CAREER_EMAIL = 'hr@onlinepatent.ru';
export const OP_PHONE = 88007005529;
export const OP_PHONE_OFFICE = 74953084819;
export const OP_PHONE_PARTNERS = 88006001291;
export const OP_COORDINATES = [55.740117, 37.565865];
export const OP_ADDRESS = 'Москва, Бережковская наб., д.\u00A06';
export const OP_SHEDULE_DAYS = 'с понедельника по\u00A0пятницу';
export const OP_SHEDULE_TIME = 'с\u00A007:00 до\u00A019:00';

export const OP_USERS_COUNT = 100000;
export const OP_SUBSCRIBERS_COUNT = 80000;

export const SOCIAL_TELEGRAM = 'https://t.me/+nigmCe8CXxdiODUy';
export const SOCIAL_TELEGRAM_BOT = 'https://t.me/onlinepatent_x_bot';
export const SOCIAL_TELEGRAM_PARTNER_CHAT = 'https://t.me/+WK3eahpHGsIxOTdi';
export const SOCIAL_DZEN = 'https://dzen.ru/onlinepatent';
export const SOCIAL_VK = 'https://vk.com/onlinepatentru';
export const SOCIAL_HABR = 'https://habr.com/ru/company/onlinepatent/blog/';
export const SOCIAL_YOUTUBE = 'https://www.youtube.com/c/onlinepatent';

export const CONNECT_TELEGRAM = 'https://t.me/onlinepatentru_bot';
export const CONNECT_VIBER = 'viber://pa?chatURI=onlinepatentru';
export const CONNECT_WHATSAPP = 'https://wa.me/79255165704';
export const CONNECT_VK = 'https://vk.com/im?sel=-61850364';
export const CONNECT_SKYPE = 'skype:support@onlinepatent.ru?chat';

export const SKOLKOVO_URL = 'http://sk.ru/';

export const SESSIONSTORAGE_TRADEMARK_LAST_SEARCH = 'trademarkLastSearch';
export const LOCALSTORAGE_DEVICE_WIDTH_KEY = 'deviceWidth';
